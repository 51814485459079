import { toSnakeCase } from "~/utils/stringUtils";

import type { LocationQuery } from "vue-router";
import type { ApiCondition, ApiField, Condition } from "~/interfaces/list";

export default function useConditionsBuilder() {
  const { baseParameters, facets } = storeToRefs(useList());

  const buildApiCondition = (condition: Condition): ApiCondition => {
    const apiCondition: ApiCondition = {
      conditions: [
        {
          field: condition.field,
          values: condition.values,
          ...(condition.operator && { operator: condition.operator }),
        },
      ],
      conjunction: condition.conjunction,
    };
    return apiCondition;
  };

  const buildApiConditions = (conditions: Condition[]): ApiCondition[] => {
    return conditions.map(buildApiCondition);
  };

  const handleDateConditions = (key: string, value: string): ApiCondition[] => {
    const field = facets.value.find((facet) => facet.type === "Datepicker")?.field ?? "field_date";

    return buildApiConditions([
      {
        field: field,
        values: value,
        operator: key === "startDate" ? ">=" : "<=",
        conjunction: "or",
      },
    ]);
  };

  const handleCheckboxConditions = (key: string, value: string): ApiCondition[] => {
    const field = toSnakeCase(key.replace("[]", ""));
    return buildApiConditions([
      {
        field: field as ApiField,
        values: value,
        conjunction: "or",
      },
    ]);
  };

  const handleRadioConditions = (key: string, value: string): ApiCondition[] => {
    const field = toSnakeCase(key);
    return buildApiConditions([{ field: field as ApiField, values: value, conjunction: "or" }]);
  };

  const buildApiConditionsFromQuery = (query: LocationQuery): ApiCondition[] => {
    const allowedFacets = facets.value.map((facet) => facet.field);

    return Object.entries(query).flatMap(([key, value]) => {
      if (!allowedFacets.includes(toSnakeCase(key.replace("[]", "")))
        || (!value || (Array.isArray(value) && !value.length))) {
        return [];
      }

      if (key === "startDate" || key === "endDate") {
        return handleDateConditions(key, value as string);
      } else if (key.endsWith("[]")) {
        return handleCheckboxConditions(key, value as string);
      } else if (!baseParameters.value.includes(key)) {
        return handleRadioConditions(key, value as string);
      }

      return [];
    });
  };

  return {
    buildApiConditions,
    buildApiConditionsFromQuery,
  };
}
