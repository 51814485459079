import { defineStore } from "pinia";
import { type NuxtPage} from "nuxt/schema";

export const ROUTE_STORE_NAME = "route";

type State = {
    _route?: NuxtPage
}


export const useRouteStore = defineStore(ROUTE_STORE_NAME, {
    state:  () : State => ({
        _route: undefined,
    }),
    actions: {
        setRoute(value: NuxtPage) {
            this._route = value;
        },
    },
    getters: {
        route: (state) =>  state._route,
    },
});